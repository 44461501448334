<template>
  <b-overlay
    :show="show"
  >
    <b-card-text>
      <h4 align="center">
        {{ operacion === 'N' ? 'Iniciar' : 'Seguimiento' }} Inspección
      </h4>
      <br>
      <b-row>
        <b-col
          v-if="operacion === 'N'"
          cols="12"
        >
          <h6>Foto de Inicio Inspección</h6>
        </b-col>
        <b-col
          v-if="operacion === 'N'"
          cols="12"
        >
          <center>
            <imagen
              :key="'inicio'"
              :leyenda="'inicio'"
              @cargar-file="imagenInicio"
            />
          </center>
        </b-col>
        <!--
        <b-col
          v-if="operacion !== 'N'"
          cols="12"
        >
          <h5>Tipo Seguimiento</h5>
        </b-col>
        <b-col
          v-if="operacion !== 'N'"
          cols="12"
        >
          <v-select
            v-model="seguimiento"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="seguimientos"
          />
        </b-col>
        -->
        <b-col
          v-if="operacion !== 'N' && seguimiento !== null"
          cols="12"
        >
          &nbsp;
        </b-col>
        <b-col
          v-if="operacion !== 'N' && seguimiento !== null && seguimiento.value === 'S'"
          cols="12"
        >
          <h6>Foto del Seguimiento</h6>
        </b-col>
        <b-col
          v-if="operacion !== 'N' && seguimiento !== null && seguimiento.value === 'S'"
          cols="12"
        >
          <center>
            <imagen
              :key="'seguimiento'"
              :leyenda="'seguimiento'"
              @cargar-file="imagenPanoramica"
            />
          </center>
        </b-col>
        <b-col
          v-if="operacion !== 'N' && seguimiento !== null && seguimiento.value === 'F'"
          cols="12"
        >
          <h6>Foto de Finalización</h6>
        </b-col>
        <b-col
          v-if="operacion !== 'N' && seguimiento !== null && seguimiento.value === 'F'"
          cols="12"
        >
          <center>
            <imagen
              :key="'fin'"
              :leyenda="'fin'"
              @cargar-file="imagenFin"
            />
          </center>
        </b-col>
        <b-col cols="12">
          &nbsp;
        </b-col>
        <b-col cols="12">
          <h5>Observaciones</h5>
          <b-form-textarea
            id="comentarios"
            v-model="comentarios"
            rows="2"
            required
          />
        </b-col>
        <b-col
          cols="12"
          class="align-content-center"
        >
          <center>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1 align-self-center"
              @click="guardarInspeccion"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Guardar Seguimiento</span>
            </b-button>
          </center>
          <br>
        </b-col>
      </b-row>
    </b-card-text>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BRow, BCol, BCardText, BFormTextarea, BButton,
} from 'bootstrap-vue'
import Imagen from '@/components/Inspecciones/Imagen.vue'
import Ripple from 'vue-ripple-directive'
import { updateCreateInspeccion } from '@/utils/inspecciones'
import { sendFile } from '@/utils/files'
import { getItem, saveItem } from '@/utils/catalogos'

export default {
  name: 'Inspeccion',
  components: {
    BOverlay,
    BRow,
    BCol,
    BCardText,
    BFormTextarea,
    Imagen,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    inspeccion: {
      type: Object,
      required: true,
    },
    operacion: {
      type: String,
      required: true,
    },
    seguimiento: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      seguimientos: [{ value: 'S', title: 'Agregar Imagen' }, { value: 'F', title: 'Finalizar Inspección' }],
      localization: null,
      show: false,
      fotoPanoramica: '',
      fotoInicio: '',
      fotoFin: '',
      comentarios: '',
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  created() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // eslint-disable-next-line no-return-assign
        pos => (
          this.localization = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          }),
        error => {
          console.error('Error Obteniendo Latitud y Longitud!', error)
          this.localization = null
        },
      )
    }
  },
  methods: {
    async guardarInspeccion() {
      if (this.comentarios === '') {
        this.error('Debe ingresar Comentarios!')
        return
      }

      if (this.fotoInicio === '' && this.operacion === 'N') {
        this.error('Debe ingresar una foto del inicio de Inspección!')
        return
      }

      if (this.operacion === 'U' && this.seguimiento === null) {
        this.error('Debe seleccionar un tipo de seguimiento!')
        return
      }

      if (this.fotoPanoramica === '' && this.operacion === 'U' && this.seguimiento.value === 'S') {
        this.error('Debe ingresar una foto del Seguimiento!')
        return
      }
      if (this.fotoFin === '' && this.operacion === 'U' && this.seguimiento.value === 'F') {
        this.error('Debe ingresar una foto del fin de Inspección!')
        return
      }
      this.show = true

      const urlBucket = `inspecciones/${this.inspeccion.id}`

      if (this.localization === null || this.localization === undefined) {
        this.error('Error obteniendo geolocalización, intente nuevamente!')
        this.show = false
        return
      }

      let imagen = null
      if (this.operacion === 'N') {
        imagen = await sendFile(this.fotoInicio, 'images', urlBucket)
      } else if (this.operacion === 'U' && this.seguimiento.value === 'S') {
        imagen = await sendFile(this.fotoPanoramica, 'images', urlBucket)
      } else if (this.operacion === 'U' && this.seguimiento.value === 'F') {
        imagen = await sendFile(this.fotoFin, 'images', urlBucket)
      }

      if (imagen !== null) {
        const file = imagen.data
        file.localization = this.localization
        file.isMobile = window.screen.width <= 760
        // eslint-disable-next-line no-nested-ternary
        file.tipo = this.operacion === 'N' ? 'Inicio Inspección' : this.seguimiento.value === 'S' ? 'Seguimiento Inspección' : 'Finalización Inspección'
        file.fechaHora = new Date()
        file.comentarios = this.comentarios
        console.log(file)
        this.inspeccion.imagenes.push(file)
      }

      let body
      if (this.operacion === 'N') {
        body = {
          id: this.inspeccion.id,
          usuario: {
            id: this.usuario.id,
            nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
            email: this.usuario.email,
          },
          imagenes: this.inspeccion.imagenes,
          observaciones: this.inspeccion.observaciones,
          estado: 'EN CURSO',
        }
      } else {
        body = {
          id: this.inspeccion.id,
          imagenes: this.inspeccion.imagenes,
          observaciones: this.inspeccion.observaciones,
          estado: this.seguimiento.value === 'F' ? 'FINALIZADA' : this.inspeccion.estado,
        }
        if (this.seguimiento.value === 'F') {
          const ruta = await getItem(8, this.inspeccion.rutaId)
          ruta.active = false
          delete ruta.localidades
          delete ruta.localidadesOff
          delete ruta.principales
          delete ruta.secundarias
          delete ruta.fechaCreacion
          delete ruta.fechaProgramada
          await saveItem(8, ruta, 'Ruta', 2)
        }
      }
      if (imagen !== null) {
        await updateCreateInspeccion(body, 1)
        this.success('Inspección', 'Inspección Actualizada Correctamente!!')
        this.$emit('cerrar-dialog')
        // setTimeout(window.location.reload(), 6000)
      } else {
        this.error('Error almacenando fotografía, intente nuevamente!')
      }

      this.show = false
    },
    success(titulo, mensaje) {
      this.$swal({
        title: titulo,
        text: mensaje,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    error(mensaje) {
      this.$swal({
        title: 'Error!',
        text: mensaje,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    imagenPanoramica(file) {
      this.fotoPanoramica = file
    },
    imagenInicio(file) {
      this.fotoInicio = file
    },
    imagenFin(file) {
      this.fotoFin = file
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
