<template>
  <div>
    <b-tabs
      content-class="pt-1"
      fill
    >
      <b-tab
        title="PENDIENTES"
      >
        <b-row>
          <b-col
            v-for="(inspeccion, o) in filtrarData('PENDIENTE')"
            :key="o"
            cols="12"
          >
            <card-inspeccion-ruta
              :inspeccion="inspeccion"
              :contador="o+1"
              :operacion="'N'"
              @refrescar-pagina="reaload()"
            />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab
        title="EN CURSO"
      >
        <b-col
          v-for="(inspeccion, o) in filtrarData('EN CURSO')"
          :key="o"
          cols="12"
        >
          <card-inspeccion-ruta
            :inspeccion="inspeccion"
            :contador="o+1"
            :operacion="'U'"
            @refrescar-pagina="reaload()"
          />
        </b-col>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab, BRow, BCol,
} from 'bootstrap-vue'
import { findInspecciones } from '@/utils/inspecciones'
import { getItem } from '@/utils/catalogos'
import CardInspeccionRuta from '@/components/Inspecciones/CardInspeccionRuta.vue'

export default {
  name: 'CardsInspeccionesRutas',
  components: {
    CardInspeccionRuta,
    BTabs,
    BTab,
    BRow,
    BCol,
  },
  props: {
    origen: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      inspecciones: [],
      inspeccionesFitradas: [],
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  created() {
    this.reaload()
  },
  methods: {
    async reaload() {
      const filter = {
        where: {
          and: [
            { 'usuario.id': this.usuario.id },
            { tipo: this.origen },
            { estado: { neq: 'FINALIZADA' } },
          ],
        },
        order: ['fechaCreacion DESC'],
      }
      this.inspecciones = await findInspecciones(filter)
      // eslint-disable-next-line no-restricted-syntax
      for await (const inspeccion of this.inspecciones) {
        inspeccion.ruta = await getItem(8, inspeccion.rutaId)
        // if (inspeccion.ruta.fechaProgramada !== null) inspeccion.ruta.fechaProgramada = formatDate(inspeccion.ruta.fechaProgramada, '/')
      }
      this.filtrarData('PENDIENTE')
    },
    filtrarData(status) {
      return this.inspecciones.filter(inspeccion => inspeccion.estado === status)
    },
  },
}
</script>

<style scoped>

</style>
