import { render, staticRenderFns } from "./CardsInspeccionesRutas.vue?vue&type=template&id=fbb4f460&scoped=true"
import script from "./CardsInspeccionesRutas.vue?vue&type=script&lang=js"
export * from "./CardsInspeccionesRutas.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbb4f460",
  null
  
)

export default component.exports