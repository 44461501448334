<template>
  <div>
    <b-card
      :header="`Inspección No. ${contador}`"
      :title="inspeccion.ruta.nombre"
      :footer="`${getDays()}`"
      class="text-center"
      footer-class="text-muted"
    >
      <b-row>
        <b-col cols="12">
          <b-input-group>
            <b-input-group-prepend is-text>
              <label>Cantidad de Localidades:</label>
            </b-input-group-prepend>
            <b-form-input
              :value="`${inspeccion.ruta.localidades? inspeccion.ruta.localidades.length : 0}`"
              readonly
            />
          </b-input-group>
        </b-col>
        <b-col cols="12">
          <br>
        </b-col>
        <b-col
          v-if="inspeccion.ruta.tipoRuta === 'DIURNA'"
          cols="12"
        >
          <b-input-group>
            <label>Fecha de Inspección:</label> &nbsp;&nbsp;
            <b-form-input
              v-model="fechaCreacion"
              readonly
            />
          </b-input-group>
        </b-col>
        <b-col
          v-if="inspeccion.estado === 'EN CURSO'"
          cols="12"
        >
          <label>Usuario:</label>
        </b-col>
        <b-col
          v-if="inspeccion.estado === 'EN CURSO'"
          cols="12"
        >
          <b-form-input
            :value="`${inspeccion.usuario.nombre}`"
            readonly
          />
        </b-col>
      </b-row>
      <br>
      <modal-inspeccion
        :inspeccion="inspeccion"
        :operacion="operacion"
        @refrescar-pagina="reaload()"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCol, BRow, BInputGroup, BInputGroupPrepend, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { diferenceBetweenToday, diferenceBetweenTodayNoAbs, formatDate } from '@/utils/fechas'
import ModalInspeccion from '@/components/Inspecciones/ModalInspeccion.vue'

export default {
  name: 'CardInspeccionRuta',
  components: {
    BCard,
    ModalInspeccion,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    inspeccion: {
      type: Object,
      required: true,
    },
    operacion: {
      type: String,
      required: true,
    },
    contador: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fechaCreacion: '',
    }
  },
  created() {
    if (this.inspeccion.tipo === 'DIURNA') this.fechaCreacion = this.dateFechaProgramada()
  },
  methods: {
    reaload() {
      this.$emit('refrescar-pagina')
    },
    dateFechaProgramada() {
      return formatDate(this.inspeccion.ruta.fechaProgramada, '/')
    },
    getDays() {
      if (this.inspeccion.ruta.fechaProgramada !== null) {
        const diff = diferenceBetweenTodayNoAbs(this.inspeccion.ruta.fechaProgramada)
        const realDiff = diferenceBetweenToday(this.inspeccion.ruta.fechaProgramada)
        if (diff > 0) return `Dentro de ${realDiff} día(s)`
        if (diff < 0) return `${realDiff} día(s) de atraso`
        return 'Hoy'
      }
      const diff = diferenceBetweenTodayNoAbs(this.inspeccion.fechaCreacion)
      const realDiff = diferenceBetweenToday(this.inspeccion.fechaCreacion)
      if (diff > 0) return `Dentro de ${realDiff} día(s)`
      if (diff < 0) return `${realDiff} día(s) de atraso`
      return 'Hoy'
    },
  },
}
</script>

<style scoped>

</style>
