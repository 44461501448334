<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      v-b-modal.modal-detalle
      variant="relief-primary"
      block
      @click="verDetallePoste(operacion==='N' ? null: { value: 'S', title: 'Agregar Imagen' })"
    >
      {{ operacion==='N' ? 'Iniciar Inspección' : 'Agregar Imagen' }}
    </b-button>
    <br>
    <b-button
      v-if="operacion !=='N'"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      v-b-modal.modal-detalle
      variant="relief-warning"
      block
      @click="redirectFalla()"
    >
      {{ 'Ingresar Falla' }}
    </b-button>
    <br>
    <b-button
      v-if="operacion!=='N'"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      v-b-modal.modal-detalle
      variant="relief-success"
      block
      @click="verDetallePoste({ value: 'F', title: 'Finalizar Inspección' })"
    >
      {{ 'Finalizar Inspección' }}
    </b-button>
    <b-modal
      v-model="verDialogoDocumento"
      hide-footer
      modal-class="modal-primary"
      centered
      title="Inspecciones"
    >
      <inspeccion-ruta
        :inspeccion="inspeccion"
        :operacion="operacion"
        :seguimiento="opcion"
        @cerrar-dialog="ocultarDialogo"
      />
    </b-modal>
  </div>

</template>

<script>
import { BModal, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import InspeccionRuta from '@/components/Inspecciones/InspeccionRuta.vue'

export default {
  name: 'ModalDetailPoste',
  components: {
    BModal,
    BButton,
    InspeccionRuta,
  },
  directives: {
    Ripple,
  },
  props: {
    inspeccion: {
      type: Object,
      required: true,
    },
    operacion: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      verDialogoDocumento: false,
      opcion: '',
    }
  },
  methods: {
    verDetallePoste(opcion) {
      this.ocultarDialogo()
      this.opcion = opcion
      this.verDialogoDocumento = true
    },
    ocultarDialogo() {
      this.verDialogoDocumento = false
      this.$emit('refrescar-pagina')
    },
    redirectFalla() {
      this.$router.replace(`/tickets/inspeccion/${this.inspeccion.id}/INSPECCION`)
    },
  },
}
</script>

<style scoped>
.card-poste {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 18px;
  border: 1px solid rgb(238, 238, 238);
}

.card-poste .text {
  color: #211f1f70;
}
</style>
